import "./App.css";
import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import SearchPage from "./pages/searchPage/SearchPage";
import { useSelector, useDispatch } from "react-redux";
import { setContext } from "apollo-link-context";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
import NewHomePage from "./pages/newHomePage/NewHomePage";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NotFoundPage from "./pages/notFoundPage/NotFoundPage";
import Footer from "./components/footer/Footer";

export default function Main() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      if (
        token &&
        token != "undefined" &&
        jwtDecode(token).exp > new Date().getMilliseconds()
      ) {
        const getUser = () => JSON.parse(localStorage.getItem("user"));
        dispatch({
          type: "SET_USER",
          payload: {
            token: localStorage.getItem("token"),
            user: getUser(),
          },
        });
        return;
      }
    } catch (err) {}
    dispatch({
      type: "SET_USER",
      payload: null,
    });
  }, []);

  const token = useSelector((state) => state.token);
  const openSnackBar = useSelector((state) => state.openSnackBar);
  const errorLink = onError(({ graphqlErrors }) => {
    if (graphqlErrors) {
      graphqlErrors.map(({ message }) => {
        alert(`Graphql error ${message}`);
        return null;
      });
    }
  });
  let theme = createTheme({
    typography: {
      fontFamily: "Montserrat",
      fontSize: 13,
    },

    palette: {
      primary: {
        main: "#517B87", // Used elsewhere
      },
      success: {
        main: "#E5863A", // custom button color (seafoam green)
        contrastText: "#ffffff", // custom button text (white)
      },
      coffee: {
        main: "#FFDD00",
      },
      error: {
        main: "#C6112E", // custom button color (red)
      },
    },
  });
  theme.typography.h3 = {
    fontSize: 45,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  };
  const link = from([
    errorLink,
    new HttpLink({ uri: "https://backend.findmyprof.com/graphql" }),
  ]);
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token && token != "undefined" ? `Bearer ${token}` : "",
      },
    };
  });

  let getClient = () => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: authLink.concat(link),
    });
  };

  let client = getClient();
  React.useEffect(() => {
    client = getClient();
  }, [token]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch({ type: "SET_OPEN_SNACKBAR", payload: false });
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route path="/" element={<NewHomePage />}></Route>
            <Route path="/search" element={<SearchPage />}></Route>
            <Route path="*" element={<NotFoundPage />}></Route>
          </Routes>
        </Router>
        <Footer />
        <Snackbar
          open={openSnackBar}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Sign in Required"
          action={action}
        />
      </ApolloProvider>
    </ThemeProvider>
  );
}
