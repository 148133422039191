import * as React from "react";
import { MenuItem, Select } from "@mui/material";
import { useQuery } from "@apollo/client";
import { LOOKUP } from "../../graphql/Query";

const SelectEntityTag = React.forwardRef((props, ref) => {
  const entityType = props.entityType;
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(
    props.value ? props.value : { departmentCode: "Dept", name: "Department" }
  );

  const { error, loading, data } = useQuery(LOOKUP, {
    variables: {
      lookupRequest: {
        entityType: entityType,
      },
    },
  });

  React.useImperativeHandle(ref, () => ({
    reset() {
      setValue(100);
    },
  }));

  React.useEffect(() => {
    if (data) {
      setOptions(data.lookup.response);
    }
  }, [data]);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  const handleChange = (e) => {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };
  return (
    <Select
      value={value}
      onChange={handleChange}
      options={options}
      sx={{ width: "200px", ...props.sx }}
      variant="standard"
      disableUnderline
      renderValue={(v) => (props.renderValue ? props.renderValue(v) : v.name)}
    >
      <MenuItem disabled value={{ departmentCode: "Dept", name: "Department" }}>
        {props.label}
      </MenuItem>
      {options && options.map((e) => <MenuItem value={e}>{e.name}</MenuItem>)}
    </Select>
  );
});
export default SelectEntityTag;
