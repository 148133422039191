import { gql } from "@apollo/client";

export const LOOKUP = gql`
  query lookup($lookupRequest: LookupRequestInput) {
    lookup(lookupRequest: $lookupRequest) {
      count
      response {
        id
        name
        ... on StandardDepartment {
          departmentCode
        }
      }
    }
  }
`;

export const FACULTY_SEARCH = gql`
  query search($searchRequest: SearchRequestInput) {
    search(searchRequest: $searchRequest) {
      count
      response {
        id
        name
        ... on Faculty {
          tries
          HIndex
          i10Index
          name
          tags
          emailId
          title
          profiles {
            profileType
            url
            __typename
          }
          rmpRating
          profileImage
          collegeId
          departmentId
          department {
            name
            facultyPageUrl
          }
          college {
            name
            city
            countryCode
            countryObject {
              name
              image
            }
          }
          tagObjects {
            name
          }
          phoneNumber
          rmpDifficultyRating
          bookmarked

          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_BOOKMARK = gql`
  query getBookmark {
    getBookmark {
      facultyIds
    }
  }
`;
