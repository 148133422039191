import "./App.css";
import * as React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { store } from "./store/Index";
import Main from "./Main";

function App() {
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="678284937135-bbdda0sugp0t1mm5nf78afmotbqngrec.apps.googleusercontent.com">
        <Main />
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
