import { Container } from "@mui/material";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Link to="/">
        <img src="/not-found.svg" maxwidth="100%" maxheight="100%" />
      </Link>
    </Container>
  );
}
