import { styled } from "@mui/material/styles";
import AdvancedSearch from "./AdvancedSearchPane";
import * as React from "react";
import Drawer from "@mui/material/Drawer";
import FilterListIcon from "@mui/icons-material/FilterList";
import Fab from "@mui/material/Fab";
import { useLocation } from "react-router-dom";

export default function Filter(props) {
  const LargeFilterProvider = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  }));
  const SmallFilterProvider = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  }));
  const [state, setState] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    setState(false);
  }, [location]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };
  const style = {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  };
  return (
    <>
      <LargeFilterProvider>
        <AdvancedSearch />
      </LargeFilterProvider>
      <SmallFilterProvider>
        <Fab onClick={toggleDrawer(true)} style={style} color="primary">
          <FilterListIcon />
        </Fab>
        <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
          <AdvancedSearch
            onClose={() => {
              setState(false);
            }}
          />
        </Drawer>
      </SmallFilterProvider>
    </>
  );
}
