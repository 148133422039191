import * as React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  Typography,
  Chip,
  Stack,
  Rating,
  Tooltip,
  Divider,
  Grid,
  Button,
  Link,
  useMediaQuery,
  Container,
} from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { BOOKMARK } from "../../graphql/Mutation";
import { Box } from "@mui/system";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getFormData } from "../../library/FormUtils";

function titleCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function nameCase(str) {
  const arr = str.split(/[\s\.,]/);
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }
  return arr.join(" ");
}

export default function FacultyListItem(props) {
  const [faculty, setFaculty] = React.useState(props.faculty);
  const [expand, setExpanded] = React.useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  React.useEffect(() => {
    setFaculty(props.faculty);
  }, [props.faculty]);
  const user = useSelector((state) => state.user);
  const [bookmarkQuery] = useMutation(BOOKMARK, {
    onCompleted: (data) => {
      dispatch({ type: "SET_BOOKMARKS", payload: data?.bookmark?.facultyIds });
      setFaculty({ ...faculty, bookmarked: !faculty.bookmarked });
    },
    onError: (e) => {
      if (e) {
        if (e.toString().includes("Access Denied")) {
          dispatch({ type: "SET_USER" });
          dispatch({ type: "SET_OPEN_SNACKBAR", payload: true });
        }
      }
    },
  });
  const [searchParams, _] = useSearchParams();

  const [formData, setFormData] = React.useState(
    getFormData(searchParams.get("query"))
  );
  const navigate = useNavigate();

  if (!faculty) {
    return <></>;
  }
  const getFacultyUrl = (faculty, type) => {
    if (type == "GOOGLE_SCHOLAR" && faculty.tries == 2) {
      return;
    }
    if (faculty.profiles) {
      const profile = faculty.profiles.filter((p) => p.profileType === type);
      if (profile && profile.length >= 1) {
        return profile[0].url;
      }
    }
    return type == "HOMEPAGE" ? faculty.department.facultyPageUrl : null;
  };

  const handleBookmark = () => {
    if (user) {
      bookmarkQuery({
        variables: { bookmark: !faculty.bookmarked, facultyId: faculty.id },
      });
      if (formData["onlyBookmarked"]) {
        navigate(
          "/search?query=" + encodeURIComponent(JSON.stringify(formData))
        );
      }
    } else {
      dispatch({ type: "SET_OPEN_SNACKBAR", payload: true });
    }
  };

  const getTagElement = () => {
    let originalTagList = faculty.tagObjects.filter((tag) => tag);
    let tagList = originalTagList
      ? [...originalTagList].sort((a, b) => b?.name?.length - a?.name?.length)
      : [];
    let hasMore = false;
    const totalElements = isSmallScreen ? 6 : 8;
    if (tagList.length > totalElements && !expand) {
      tagList = tagList.slice(0, totalElements);
      hasMore = true;
    }
    return (
      <>
        {tagList.map((tag) => {
          if (tag) {
            return (
              <Chip
                label={titleCase(tag.name)}
                sx={{
                  color: "#5D616B",
                  height: { xs: "24px", md: "32px" },
                  marginBottom: { xs: "4px", md: "0px" },
                }}
              />
            );
          }
        })}
        {hasMore && (
          <Box height="30px" sx={{ display: "flex", alignItems: "center" }}>
            <Link
              variant="body2"
              onClick={() => setExpanded(true)}
              sx={{ textDecoration: "none" }}
            >
              +{originalTagList.length - totalElements}
            </Link>
          </Box>
        )}
      </>
    );
  };

  const getLinksElement = (faculty) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          fontSize: "14px",
        }}
      >
        {getFacultyUrl(faculty, "GOOGLE_SCHOLAR") ? (
          <>
            <a href={getFacultyUrl(faculty, "GOOGLE_SCHOLAR")} target="_blank">
              H index: {faculty.HIndex}
            </a>
            <Divider
              orientation="vertical"
              style={{
                marginRight: "7px",
                marginLeft: "7px",
                height: "14px",
              }}
            />
            <a href={getFacultyUrl(faculty, "GOOGLE_SCHOLAR")} target="_blank">
              i10 index: {faculty.i10Index}
            </a>
          </>
        ) : (
          <></>
        )}
        {getFacultyUrl(faculty, "RATE_MY_PROF") ? (
          <>
            {getFacultyUrl(faculty, "GOOGLE_SCHOLAR") && (
              <Divider
                orientation="vertical"
                style={{
                  marginRight: "7px",
                  marginLeft: "7px",
                  height: "14px",
                }}
              />
            )}
            <a href={getFacultyUrl(faculty, "RATE_MY_PROF")} target="_blank">
              Review
            </a>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const TitleLinkElement = (faculty) => {
    return (
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        {getLinksElement(faculty)}
      </Box>
    );
  };
  const BodyLinkElement = (faculty) => {
    return (
      <Box sx={{ display: { lg: "none" }, marginTop: "8px" }}>
        {getLinksElement(faculty)}
      </Box>
    );
  };

  const TitleElement = (faculty) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {faculty.title ? (
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {faculty.title}
                </Typography>{" "}
                <Divider
                  orientation="vertical"
                  style={{ marginRight: "7px", marginLeft: "7px" }}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {faculty.department.name ? (
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {faculty.department.name}
                </Typography>{" "}
                <Divider
                  orientation="vertical"
                  style={{ marginRight: "7px", marginLeft: "7px" }}
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {faculty.college.name ? (
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {faculty.college.name}
                </Typography>{" "}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {BodyLinkElement(faculty)}
      </>
    );
  };

  const getNavigationButtons = (faculty, direction) => {
    return (
      <Grid
        direction={direction}
        container
        spacing={1}
        sx={{ maxWidth: "300px", justifyContent: "flex-end" }}
      >
        <Grid xs={5} item>
          <Button
            disableElevation
            sx={{
              display: "flex",
              textTransform: "none",
              justifyContent: "flex-start",
              textAlign: "start",
              paddingLeft: "0px",
              width: { md: "100%", xs: "130px" },
            }}
            color="success"
            onClick={handleBookmark}
            size={direction === "row" ? "small" : "medium"}
          >
            {faculty.bookmarked ? (
              <>
                <BookmarkIcon /> Bookmarked
              </>
            ) : (
              <>
                <BookmarkBorderIcon /> Bookmark
              </>
            )}
          </Button>
        </Grid>
        <Grid xs={6} item>
          <Button
            disableElevation
            variant="contained"
            sx={{
              textTransform: "none",
              width: { md: "100%", xs: "130px" },
              height: "36px",
            }}
            color="success"
            size="small"
            href={getFacultyUrl(faculty, "HOMEPAGE")}
            target="_blank"
          >
            View More
          </Button>
        </Grid>
      </Grid>
    );
  };

  const getNavigationButtonsSM = (faculty) => {
    return (
      <Box
        sx={{
          display: { md: "none", xs: "flex" },
          justifyContent: "flex-end",
          flexDirection: "row",
        }}
      >
        {getNavigationButtons(faculty, "row")}
      </Box>
    );
  };

  const getNavigationButtonsLG = (faculty) => {
    return (
      <Box sx={{ display: { md: "block", xs: "none" } }}>
        {getNavigationButtons(faculty, "column")}
      </Box>
    );
  };

  const getLocation = (faculty) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Avatar
          alt=""
          variant="circular"
          src={
            "https://hatscripts.github.io/circle-flags/flags/" +
            faculty.college.countryCode.toLowerCase() +
            ".svg"
          }
          sx={{
            width: { md: 25, xs: 18 },
            height: { md: 25, xs: 18 },
            paddingRight: 1,
          }}
        />
        <Typography
          sx={{ display: "inline" }}
          component="span"
          variant="body2"
          color="text.primary"
        >
          {(faculty.college.city ? faculty.college.city + ", " : "") +
            faculty.college.countryCode}
        </Typography>
      </div>
    );
  };

  return (
    <ListItem sx={{ paddingLeft: "0", paddingRight: "0" }}>
      <Grid
        container
        spacing={1}
        sx={{ width: "100%", margin: "0 !important" }}
      >
        <Grid xs={12} md={9} lg={10}>
          <Stack>
            <ListItem
              alignItems="flex-start"
              sx={{ paddingLeft: "0", paddingRight: "0", paddingTop: "0px" }}
            >
              <ListItemAvatar style={{ marginTop: "0px" }}>
                <Avatar
                  alt={faculty.name}
                  src={faculty.profileImage}
                  sx={{ width: { md: 56, xs: 50 }, height: { md: 56, xs: 50 } }}
                  variant="rounded"
                />
              </ListItemAvatar>

              <Stack
                sx={{
                  marginLeft: { md: "20px", xs: "10px" },
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Grid
                  container
                  spacing={1}
                  sx={{ width: "100%", margin: "0 !important" }}
                >
                  <Grid xs={7} md={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        className="head"
                        sx={{
                          marginRight: "5px",
                          fontSize: { xs: "12", md: "large" },
                        }}
                      >
                        {nameCase(faculty.name)}
                      </Box>
                      <Tooltip
                        title={
                          faculty.rmpRating
                            ? "Rate My Professor Rating: " +
                              faculty.rmpRating.toFixed(1)
                            : "Rate My Professor Rating not found"
                        }
                        arrow
                      >
                        <div>
                          <Rating
                            value={faculty.rmpRating}
                            readOnly
                            precision={0.1}
                            style={{ paddingRight: "20px" }}
                            disabled={
                              faculty.rmpRating === null ||
                              faculty.rmpRating === 0
                            }
                          />
                        </div>
                      </Tooltip>
                      {TitleLinkElement(faculty)}
                    </div>
                  </Grid>
                  <Grid xs={5} md={0} sx={{ display: { md: "none" } }}>
                    {getLocation(faculty)}
                  </Grid>
                </Grid>
                {!isSmallScreen && TitleElement(faculty)}
              </Stack>
            </ListItem>
            {isSmallScreen && TitleElement(faculty)}
            <Container
              sx={{
                padding: "0px !important",
                display: "flex",
                gap: { md: "8px", xs: "4px" },
                flexDirection: "row",
                alignItems: "flex-start",
                flexWrap: "wrap",
                paddingBottom: "8px",
                textAlign: "center",
                marginTop: { xs: "10px", md: 0 },
                marginBottom: { xs: "8px", md: 0 },
              }}
            >
              {getTagElement()}
            </Container>
            {getNavigationButtonsSM(faculty)}
          </Stack>
        </Grid>
        <Grid
          xs={0}
          md={3}
          lg={2}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "100%",
              gap: "20px",
            }}
          >
            {getLocation(faculty)}
            <div>{getNavigationButtonsLG(faculty)}</div>
          </div>
        </Grid>
      </Grid>
    </ListItem>
  );
}
