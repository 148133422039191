import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { getFormData } from "../library/FormUtils";

export default function FormProvider() {
  const [searchParams, _] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const rv = getFormData(searchParams.get("query"));
    dispatch({ type: "SET_SUBMITTED_DATA", payload: rv });
  }, [location]);
  return <></>;
}
