import {
  hIndexRange,
  rateMyProfessorRange,
  qsRankSliderValues,
} from "../components/advancedSearch/Constants";

function getValue(arr, i) {
  if (i >= arr.length - 1 || i <= 0) {
    return null;
  }
  return arr[i];
}

function getRange(arr, v) {
  if (!v) {
    return null;
  }
  return {
    minimum: getValue(arr, v[0]),
    maximum: getValue(arr, v[1]),
  };
}

function getIds(arr) {
  if (arr) {
    return arr.map((i) => i.id);
  }
  return null;
}

export default function getSearchRequest(formData) {
  if (!formData) {
    return {
      page: {
        pageNumber: 0,
        pageSize: 10,
      },
    };
  }
  let rv = {};
  rv["countries"] = formData["countries"];
  rv["collegeIds"] = getIds(formData["collegeIds"]);
  rv["departmentIds"] = getIds(formData["departmentIds"]);
  rv["tagIds"] = getIds(formData["tag"]);
  rv["page"] = {
    pageNumber: formData["page"] ? formData["page"] : 0,
    pageSize: 10,
  };
  rv["hindexRange"] = getRange(hIndexRange, formData["hIndex"]);
  rv["qsRankingRange"] = getRange(qsRankSliderValues, formData["qsRank"]);
  rv["departmentQSRankingRange"] = getRange(
    qsRankSliderValues,
    formData["departmentQSRank"]
  );
  rv["rateMyProfRating"] = getRange(rateMyProfessorRange, formData["rmp"]);
  rv["includeWithoutGSProfile"] = formData["includeWithoutGSProfile"];
  rv["includeWithoutRMPProfile"] = formData["includeWithoutRMPProfile"];
  rv["searchQuery"] = formData["searchQuery"];
  rv["onlyBookmarked"] = formData["onlyBookmarked"];

  return rv;
}

export const defaultData = {
  includeWithoutGSProfile: true,
  includeWithoutRMPProfile: true,
  searchQuery: "",
  countries: [],
  page: 0,
};

export function getFormData(query) {
  try {
    let formData = JSON.parse(decodeURIComponent(query));
    if (!formData) {
      return defaultData;
    }
    return formData;
  } catch (err) {}
  return defaultData;
}
