import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";

export default function SearchBar(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const formData = useSelector((state) => state.formData);
  const dispatch = useDispatch();
  const handleChange = (v) => {
    dispatch({
      type: "SET_FORM_DATA",
      payload: { ...formData, searchQuery: v },
    });
    if (props.onChange) {
      props.onChange(v);
    }
  };

  const navigate = useNavigate();
  const onSubmit = () => {
    return navigate(
      "/search?query=" +
        encodeURIComponent(JSON.stringify({ ...formData, page: 0 }))
    );
  };

  return (
    <OutlinedInput
      className="search-bar"
      sx={{
        borderRadius: "100px",
        width: { md: "600px", xs: "500px" },
        paddingLeft: "5px",
        paddingRight: { sm: "25px", xs: "15px" },
        height: { sm: "45px", xs: "35px" },
        backgroundColor: "white",
        ...props.sx,
      }}
      value={formData?.searchQuery ? formData?.searchQuery : ""}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          onSubmit();
        }
      }}
      onChange={(e) => handleChange(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          {formData?.searchQuery && !isSmallScreen && (
            <>
              <IconButton edge="end" onClick={() => handleChange("")}>
                <ClearIcon />
              </IconButton>
              <div
                style={{
                  height: "35px",
                  width: "1px",
                  backgroundColor: "#909090",
                  marginLeft: "15px",
                  marginRight: "5px",
                }}
              ></div>
            </>
          )}
          <IconButton edge="end" onClick={onSubmit}>
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
      placeholder={isSmallScreen ? "Search" : "Search by name, country, etc..."}
    />
  );
}
