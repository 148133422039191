import * as React from "react";
import FacultyList from "../../components/FacultyList";
import SearchBar from "../../components/formComponents/SearchBar";
import FormProvider from "../../components/FormProvider";
import TopBar from "../../components/topBar/Index";
function SearchPage() {
  return (
    <>
      <FormProvider />
      <TopBar>
        <SearchBar />
      </TopBar>
      <FacultyList />
    </>
  );
}
export default SearchPage;
