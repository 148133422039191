import {
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SIDE_PADDING } from "../../library/StyleSheet";

export default function UtilitySection(props) {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100vw",
        maxWidth: "100vw !important",
        padding: "0 !important",
        ...props.sx,
      }}
    >
      <Container
        sx={{
          maxWidth: { md: "50%", xs: "100%" },
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          margin: "0 !important",
          paddingLeft: SIDE_PADDING,
          paddingRight: { ...SIDE_PADDING, md: "0 !important" },
          // marginLeft: "0 !important",
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          className="head"
          sx={{
            fontWeight: 600,
          }}
        >
          Find Your Ideal Research Mentor - Search by{" "}
          <span style={{ color: "#E5863A" }}>Interests</span>,{" "}
          <span style={{ color: "#E5863A" }}>Ratings</span>, &{" "}
          <span style={{ color: "#E5863A" }}>More</span>
        </Typography>
        <Typography>
          Selecting the right professor is crucial for success in graduate
          studies or research internships. Focus on your academic and research
          goals, and let us help you find the right professor by providing you
          with the following:
        </Typography>
        <List sx={{ width: "100%", padding: 0 }}>
          <ListItem
            sx={{ padding: "0px", paddingTop: "8px" }}
            alignItems="flex-start"
          >
            <ListItemAvatar sx={{ minWidth: "34px" }}>
              <CheckCircleIcon color="primary" />
            </ListItemAvatar>
            <ListItemText primary="A comprehensive database of professors, searchable by name, research interest, h-index, and college affiliation" />
          </ListItem>
          <ListItem sx={{ padding: "0px" }} alignItems="flex-start">
            <ListItemAvatar sx={{ minWidth: "34px" }}>
              <CheckCircleIcon color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Rate My Professor ratings for each professor, to provide insight into their teaching style and student interactions" />
          </ListItem>
          <ListItem sx={{ padding: "0px" }} alignItems="flex-start">
            <ListItemAvatar sx={{ minWidth: "34px" }}>
              <CheckCircleIcon color="primary" />
            </ListItemAvatar>
            <ListItemText primary="A user-friendly interface that allows for easy navigation and filtering of search results" />
          </ListItem>
        </List>
      </Container>
      <Container
        sx={{
          height: { md: "100%", xs: "50%" },
          maxWidth: { md: "50%", sm: "60%", xs: "80%" },
          minWidth: "250px",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingRight: SIDE_PADDING,
          paddingLeft: SIDE_PADDING,
        }}
      >
        <img src="/mid.svg" width="100%" height="100%" />
      </Container>
    </Container>
  );
}
