import * as React from "react";
import {
  Typography,
  Box,
  Button,
  Divider,
  FormLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Stack,
  Container,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import EntityTag from "../formComponents/EntityTag";
import ClearIcon from "@mui/icons-material/Clear";

import {
  countriesOptions,
  qsRankSliderValues,
  qsRankMarkIndexes,
  hIndexRange,
  rateMyProfessorRange,
} from "./Constants";

import DoubleSlider from "../formComponents/DoubleSlider";
import "./AdvancedSearchPane.css";
import { useNavigate } from "react-router-dom";
import { defaultData } from "../../library/FormUtils";
import { useDispatch, useSelector } from "react-redux";

export default function AdvancedSearch(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const formData = useSelector((state) => state.formData);
  const dispatch = useDispatch();

  const onChange = (key, value) => {
    dispatch({ type: "SET_FORM_DATA", payload: { ...formData, [key]: value } });
  };

  const entitySearchRef = {
    department: React.useRef(null),
    college: React.useRef(null),
    tag: React.useRef(null),
  };
  const onReset = () => {
    entitySearchRef.department.current.reset();
    entitySearchRef.college.current.reset();
    entitySearchRef.tag.current.reset();
  };

  const navigate = useNavigate();

  const onSubmit = () => {
    return navigate(
      "/search?query=" +
        encodeURIComponent(JSON.stringify({ ...formData, page: 0 }))
    );
  };
  if (!formData) {
    return <></>;
  }

  return (
    <Box
      style={{
        width: "100%",
        maxWidth: "450px",
      }}
      sx={{ boxShadow: 2, borderRadius: 3 }}
      role="presentation"
    >
      <div className="top-bar">
        <Typography variant="h5" component="h2" className="head">
          Filters
        </Typography>
        <Box>
          <Button
            color="success"
            style={{
              color: "#5A7A85",
              width: "100px",
            }}
            onClick={() => {
              dispatch({
                type: "SET_FORM_DATA",
                payload: { ...defaultData, searchQuery: formData.searchQuery },
              });
              onReset();
            }}
          >
            Reset
          </Button>
          {isSmallScreen ? (
            <IconButton edge="end" onClick={() => props.onClose()}>
              <ClearIcon />
            </IconButton>
          ) : (
            <></>
          )}
        </Box>
      </div>

      <Divider />
      <Container className="form-li-item">
        <FormLabel>Departments*</FormLabel>
        <EntityTag
          entityType="STANDARD_DEPARTMENT"
          label="Departments"
          onChange={(v) => onChange("departmentIds", v)}
          ref={entitySearchRef.department}
          value={formData["departmentIds"]}
          sx={{ marginTop: "16px" }}
        ></EntityTag>
      </Container>
      <Divider />

      <Container className="form-li-item">
        <FormLabel>Countries</FormLabel>
        <Stack spacing={3} style={{ width: "100%" }}>
          <Autocomplete
            disableCloseOnSelect
            multiple
            options={countriesOptions}
            getOptionLabel={(option) => option.emoji + " " + option.name}
            onChange={(_, v) =>
              onChange(
                "countries",
                v.map((i) => i.code)
              )
            }
            value={
              formData["countries"]
                ? countriesOptions.filter((option) =>
                    formData["countries"].includes(option.code)
                  )
                : []
            }
            popupIcon={<SearchIcon />}
            sx={{
              [`& .${autocompleteClasses.popupIndicator}`]: {
                transform: "none",
              },
              marginTop: "16px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={"Search"}
              />
            )}
          />
        </Stack>
      </Container>
      <Divider />
      <Container className="form-li-item">
        <FormLabel>Colleges</FormLabel>
        <EntityTag
          entityType="COLLEGE"
          label="Colleges"
          onChange={(v) => onChange("collegeIds", v)}
          ref={entitySearchRef.college}
          value={formData["collegeIds"]}
          sx={{ marginTop: "16px" }}
        />
      </Container>
      <Divider />
      <Container className="form-li-item">
        <DoubleSlider
          values={qsRankSliderValues}
          markIndexes={qsRankMarkIndexes}
          label="QS Rank"
          onChange={(v) => onChange("qsRank", v)}
          value={formData["qsRank"]}
          sx={{ marginBottom: "16px" }}
        />
      </Container>
      <Divider />
      <Container className="form-li-item">
        <DoubleSlider
          values={qsRankSliderValues}
          markIndexes={qsRankMarkIndexes}
          label="Department QS Rank"
          onChange={(v) => onChange("departmentQSRank", v)}
          value={formData["departmentQSRank"]}
          sx={{ marginBottom: "16px" }}
        />
      </Container>
      <Divider />
      <Container className="form-li-item">
        <FormLabel>Research Interests</FormLabel>
        <EntityTag
          entityType="TAG"
          label="Research Interests"
          onChange={(v) => onChange("tag", v)}
          ref={entitySearchRef.tag}
          value={formData["tag"]}
          sx={{ marginTop: "16px" }}
        />
      </Container>
      <Divider />
      <Container className="form-li-item">
        <DoubleSlider
          values={hIndexRange}
          markIndexes={[0, 1, 2, 3, 4, 5, 6]}
          label="H-index"
          onChange={(v) => {
            onChange("hIndex", v);
          }}
          value={formData["hIndex"]}
          sx={{ marginBottom: "16px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData["includeWithoutGSProfile"]}
              onChange={(e) =>
                onChange("includeWithoutGSProfile", e.target.checked)
              }
            />
          }
          label="Include Professors without google-scholar profile"
        />
      </Container>
      <Divider />
      <Container className="form-li-item">
        <DoubleSlider
          values={rateMyProfessorRange}
          markIndexes={[0, 2, 4, 6, 8, 10]}
          label="Rate my professor"
          onChange={(v) => {
            onChange("rmp", v);
          }}
          value={formData["rmp"]}
          sx={{ marginBottom: "16px" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData["includeWithoutRMPProfile"]}
              onChange={(e) =>
                onChange("includeWithoutRMPProfile", e.target.checked)
              }
            />
          }
          label="Include Professors without Rate my professor profile"
        />
      </Container>
      <div>
        <Divider textAlign="left" />
        <div className="right-align">
          <Button
            color="primary"
            variant="contained"
            style={{ width: "90%", backgroundColor: "#5A7A85" }}
            className="float-right"
            onClick={onSubmit}
            disableElevation
          >
            Search
          </Button>
        </div>
      </div>
    </Box>
  );
}
