import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { SIDE_PADDING } from "../../library/StyleSheet";

export default function Footer() {
  return (
    <Paper sx={{ bottom: 0, width: "100%" }} elevation={1}>
      <BottomNavigation
        showLabels
        sx={{
          backgroundColor: "#111111",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: SIDE_PADDING,
          paddingRight: SIDE_PADDING,
        }}
      >
        <Typography color="white" variant="body2">
          Find My Professor © Copyright
        </Typography>
        <Button
          disableElevation
          variant="contained"
          sx={{
            textTransform: "none",
            width: { md: "200px", xs: "150px" },
            height: "36px",
          }}
          color="coffee"
          size="small"
          href="https://www.buymeacoffee.com/findmyprofessor"
          target="_blank"
        >
          <img
            src="/bmc-logo-yellow.png"
            width="20px"
            alt="Buy me a coffee"
            style={{ marginRight: "10px" }}
          />
          <Typography variant="body2">Buy me a coffee</Typography>
        </Button>
      </BottomNavigation>
    </Paper>
  );
}
