import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import SelectEntityTag from "./SelectEntityTag";
import { Box, Button, Container, TextField } from "@mui/material";

export default function FrontPageSearch(props) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [text, setText] = React.useState(props.value);
  const [departmentId, setDepartmentId] = React.useState(null);
  const handleChange = (v) => {
    setText(v);
    if (props.onChange) {
      props.onChange(v);
    }
  };

  React.useEffect(() => {
    setText(props.value);
  }, [props.value]);
  const navigate = useNavigate();
  const onSubmit = () => {
    return navigate(
      "/search?query=" +
        encodeURIComponent(
          JSON.stringify({
            searchQuery: text,
            departmentIds: departmentId ? [departmentId] : null,
            page: 0,
          })
        )
    );
  };
  const height = { xs: "55px", md: "130px" };
  const setDepartment = (v) => {
    setDepartmentId(v);
  };

  const departmentSelector = (
    <>
      <Box
        sx={{
          height: height,
          width: "1px",
          backgroundColor: "#DBDBDB",
          marginLeft: "15px",
          marginRight: "5px",
          display: { xs: "none", sm: "block" },
        }}
      ></Box>
      <SelectEntityTag
        entityType="STANDARD_DEPARTMENT"
        label="Departments"
        onChange={setDepartment}
        value={departmentId}
        renderValue={(e) => (isSmallScreen ? e.departmentCode : e.name)}
        sx={{
          width: { xs: "65px", sm: "150px" },
          color: "#5D616B",
          marginLeft: { xs: 0, sm: "20px" },
        }}
      />
      <Box
        sx={{
          height: height,
          width: "1px",
          backgroundColor: "#DBDBDB",
          marginLeft: "15px",
          marginRight: "5px",
          display: { xs: "block", sm: "none" },
        }}
      ></Box>
    </>
  );

  return (
    <Box
      sx={{
        width: "100%",
        padding: "0 !important",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <TextField
        autoFocus
        className="search-bar"
        variant="standard"
        sx={{
          borderRadius: { xs: "8px", md: "12px" },
          width: "100%",
          boxSizing: "border-box",
          paddingLeft: "10px",
          paddingRight: { sm: "25px", xs: "10px" },
          height: height,
          display: "flex",
          justifyContent: "center",
          boxShadow: "0px 10px 36px 2px rgba(218, 218, 218, 0.32)",
          ...props.sx,
        }}
        value={text}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
        onChange={(e) => handleChange(e.target.value)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                marginLeft: { xs: "20px", md: "40px" },
                marginRight: { xs: "10px", md: "20px" },
              }}
            >
              {isSmallScreen ? departmentSelector : <SearchIcon />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {text && !isSmallScreen && (
                <IconButton edge="end" onClick={() => setText("")}>
                  <ClearIcon />
                </IconButton>
              )}

              {!isSmallScreen ? departmentSelector : <></>}
              <Button
                disableElevation
                color="success"
                variant="contained"
                sx={{
                  width: { md: "170px", sm: "100px", xs: "35px" },
                  minWidth: 0,
                  height: { md: "60px", xs: "35px" },
                  marginLeft: { sm: "40px", xs: 0 },
                }}
                onClick={onSubmit}
              >
                {isSmallScreen ? <SearchIcon /> : "Search"}
              </Button>
            </InputAdornment>
          ),
        }}
        placeholder={
          isSmallScreen ? "Search" : "Search by name, country, etc..."
        }
      />
    </Box>
  );
}
