import * as React from "react";
import { Typography, Slider, Box } from "@mui/material";

export default function DoubleSlider(props) {
  const values = props.values;
  const [value, setValue] = React.useState(
    props.value ? props.value : [0, props.values.length - 1]
  );
  const marks = [];
  if (props.markIndexes) {
    props.markIndexes.forEach((i) =>
      marks.push({ value: i, label: values[i] })
    );
  }

  function valuetext(value) {
    return values[value];
  }

  const handleChange = (event, newValue, activeThumb) => {
    const minDistance = 1;
    if (activeThumb === 0) {
      newValue[0] = Math.min(newValue[0], values.length - 1 - minDistance);
      newValue[1] = Math.max(newValue[0] + minDistance, value[1]);
    } else {
      newValue[1] = Math.max(newValue[1], minDistance);
      newValue[0] = Math.min(value[0], newValue[1] - minDistance);
    }
    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };
  return (
    <>
      <Typography
        id="non-linear-slider"
        gutterBottom
        style={{ color: "rgba(0, 0, 0, 0.6)" }}
        sx={props.sx}
      >
        {props.label}: {valuetext(props.value ? props.value[0] : 0)}-
        {valuetext(props.value ? props.value[1] : props.values.length - 1)}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Slider
          getAriaLabel={() => props.label}
          value={props.value ? props.value : [0, props.values.length - 1]}
          onChange={handleChange}
          valueLabelDisplay="auto"
          step={1}
          min={0}
          max={props.values.length - 1}
          getAriaValueText={valuetext}
          valueLabelFormat={valuetext}
          disableSwap
          marks={marks}
        />
      </Box>
    </>
  );
}
