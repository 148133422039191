import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
export default function FAQ(props) {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, v) => setExpanded(v)}
      sx={{ border: 0, boxShadow: 0, padding: 0 }}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon color="success" />
          ) : (
            <AddIcon color="success" />
          )
        }
        sx={{ flexDirection: "row-reverse", gap: 1, padding: 0 }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: "50px" }}>
        <Typography>{props.body}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
