import * as React from "react";
import {
  List,
  Divider,
  Pagination,
  Box,
  Stack,
  Grid,
  Skeleton,
} from "@mui/material";
import { FACULTY_SEARCH } from "../graphql/Query";
import { useQuery } from "@apollo/client";
import FacultyListItem from "./facultyListItem/FacultyListItem";
import BottomNavigation from "@mui/material/BottomNavigation";
import { useNavigate } from "react-router-dom";
import getSearchRequest from "../library/FormUtils";
import Filter from "./advancedSearch/Filter";
import { useSelector } from "react-redux";
import { SIDE_PADDING } from "../library/StyleSheet";

export default function FacultyList() {
  const [totalPages, setTotalPages] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const submittedFormData = useSelector((state) => state.submittedFormData);
  const bookmarks = useSelector((state) => state.bookmarks);

  const { loading, data, refetch } = useQuery(FACULTY_SEARCH, {
    fetchPolicy: "no-cache",
    variables: {
      searchRequest: getSearchRequest(submittedFormData),
    },
  });

  const [state, setState] = React.useState([]);
  const navigate = useNavigate();
  const handleChange = (_, value) => {
    submittedFormData.page = value - 1;
    return navigate(
      "/search?query=" + encodeURIComponent(JSON.stringify(submittedFormData))
    );
  };
  React.useEffect(() => {
    if (submittedFormData && submittedFormData["onlyBookmarked"]) {
      refetch({ searchRequest: getSearchRequest(submittedFormData) });
    }
  }, [bookmarks]);
  React.useEffect(() => {
    if (data) {
      setState(data.search.response);
      setCount(data.search.count);
      setTotalPages(Math.floor((data.search.count + 9) / 10));
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [data]);

  return (
    <>
      <Box
        sx={{
          margin: 0,
          padding: 0,
          paddingLeft: SIDE_PADDING,
          paddingRight: SIDE_PADDING,
        }}
      >
        <Box
          style={{
            color: "#5D616B",
            fontSize: "14px",
            paddingTop: "16px",
          }}
        >
          Showing {count} Professors
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "20px",
            gap: { lg: "40px" },
          }}
        >
          {!loading && count == 0 ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px !important",
                marginLeft: "0px !important",
              }}
            >
              <Box sx={{ width: { xs: "300px", md: "600px" } }}>
                <img src="/no-results.svg" width="100%" />
              </Box>
            </Box>
          ) : (
            <List
              sx={{
                width: {
                  xl: "900px",
                  lg: "700px",
                  xs: "100%",
                  display: "flex",
                  gap: 10,
                  flexDirection: "column",
                },
              }}
            >
              {loading ? getSkeletonList() : getList(state)}
            </List>
          )}
          <Filter />
        </Box>
      </Box>
      <Divider />
      {getFooter(totalPages, submittedFormData, handleChange)}
    </>
  );
}
function getFooter(totalPages, submittedFormData, handleChange) {
  return (
    <BottomNavigation style={{ paddingTop: "10px" }} showLabels>
      <div>
        <Pagination
          count={totalPages}
          page={submittedFormData ? submittedFormData["page"] + 1 : 1}
          onChange={handleChange}
        />
      </div>
    </BottomNavigation>
  );
}

function getList(state) {
  return (
    <>
      {state.map((faculty) => {
        if (!faculty) return <></>;
        return (
          <>
            <FacultyListItem faculty={faculty} />
            <Divider component="li" />
          </>
        );
      })}
    </>
  );
}

function getSkeletonList() {
  let list = [];
  for (let i = 0; i < 10; i++) {
    list.push(getSkeleton());
  }
  return list;
}

function getSkeleton() {
  return (
    <>
      <Grid container spacing={10}>
        <Grid item md={8} xs={12}>
          <Stack spacing={1} sx={{ paddingBottom: "30px" }}>
            <Grid container sx={{ height: "60px" }}>
              <Grid
                item
                xs={2}
                sx={{
                  height: "100%",
                  justifyContent: "start",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Skeleton
                  variant="rounded"
                  sx={{
                    width: { xs: "40px", md: "60px" },
                    height: { xs: "40px", md: "60px" },
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Stack spacing={0}>
                  <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "1rem" }}
                    width="70%"
                  />
                </Stack>
              </Grid>
            </Grid>
            <Skeleton variant="rounded" height={60} width="80%" />
          </Stack>
        </Grid>
        <Grid
          item
          md={4}
          xs={0}
          sx={{ display: { md: "flex", xs: "none" }, alignItems: "center" }}
        >
          <Skeleton variant="rounded" height={120} width="100%" />
        </Grid>
      </Grid>
      <Divider component="li" />
    </>
  );
}
