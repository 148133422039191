import { Box, Chip, Container, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SIDE_PADDING } from "../../library/StyleSheet";
import FrontPageSearch from "../formComponents/FrontPageSearch";
//Todo: Add image height 75 for mobile
export default function SearchSection(props) {
  const mostPopularSearches = [
    "Massachusetts Institute of Technology",
    "United Kingdom",
    "Computer Science",
    "Artificial Intelligence",
    "United States Computer Science",
    "Austin Quantum",
  ];
  const navigate = useNavigate();
  const setValue = (value) => {
    return navigate(
      "/search?query=" +
        encodeURIComponent(JSON.stringify({ searchQuery: value, page: 0 }))
    );
  };
  return (
    <Container
      sx={{
        paddingLeft: SIDE_PADDING,
        paddingRight: SIDE_PADDING,
        margin: 0,
        maxWidth: "100% !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FDFBF8",
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        className="head"
        sx={{
          fontWeight: 600,
          textAlign: { xs: "center", md: "start" },
          color: "#2D4C5E",
          marginTop: "105px",
          marginBottom: "48px",
        }}
      >
        Search Across <span style={{ color: "#E5863A" }}>4000+</span> Professors
      </Typography>
      <Box sx={{ padding: "0 !important", margin: "0 !important" }}>
        <img src="/monuments.svg" width="100%" maxWidth="750px" />
      </Box>
      <FrontPageSearch
        sx={{
          backgroundColor: "white",
          border: "1px solid #DBDBDB",
          marginTop: "-5px",
          maxWidth: "1200px",
        }}
      />
      <Box
        sx={{
          maxWidth: "1200px",
          margin: { md: "40px 0", xs: "20px 0" },
          alignItems: "start",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Typography
          variant="body"
          sx={{
            fontWeight: "700 !important",
            color: "#5D616B",
            marginBottom: "16px",
          }}
        >
          Most Popular Searches
        </Typography>
        <Divider sx={{ width: "100%", marginBottom: "16px" }} />
        <Box
          sx={{
            display: "flex",
            gap: { md: 2, xs: "8px" },
            flexWrap: "wrap",
          }}
        >
          {mostPopularSearches.map((v) => (
            <Chip
              label={v}
              sx={{ color: "#5D616B" }}
              onClick={() => setValue(v)}
            />
          ))}
        </Box>
      </Box>
      <Divider sx={{ width: "100vw" }} />
    </Container>
  );
}
