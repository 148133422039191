import { createStore } from "redux";
const reducerFn = (state = {}, action) => {
  if (action.type === "SET_USER") {
    if (action.payload?.user) {
      localStorage.setItem("user", JSON.stringify(action.payload?.user));
    } else {
      localStorage.removeItem("user");
    }
    const token = action.payload?.token;
    if (token) {
      localStorage.setItem("token", action.payload?.token);
    } else {
      localStorage.removeItem("token");
    }

    return {
      ...state,
      user: action.payload?.user,
      credential: action.payload?.credential,
      token: action.payload?.token,
    };
  }
  if (action.type === "SET_SUBMITTED_DATA") {
    return {
      ...state,
      formData: action.payload,
      submittedFormData: action.payload,
    };
  }
  if (action.type === "SET_FORM_DATA") {
    return {
      ...state,
      formData: action.payload,
    };
  }
  if (action.type === "SET_BOOKMARKS") {
    return {
      ...state,
      bookmarks: action.payload,
    };
  }
  if (action.type === "SET_OPEN_SNACKBAR") {
    return {
      ...state,
      openSnackBar: action.payload,
    };
  }
  return state;
};

export const store = createStore(reducerFn);
