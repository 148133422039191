import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { LOOKUP } from "../../graphql/Query";
import { useQuery } from "@apollo/client";
import SearchIcon from "@mui/icons-material/Search";
import { autocompleteClasses } from "@mui/material/Autocomplete";

const EntityTag = React.forwardRef((props, ref) => {
  const entityType = props.entityType;
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(props.value ? props.value : []);
  const [inputValue, setInputValue] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const { error, _, data, refetch } = useQuery(LOOKUP, {
    variables: {
      lookupRequest: {
        entityType: entityType,
      },
    },
  });

  React.useImperativeHandle(ref, () => ({
    reset() {
      setValue([]);
      setInputValue("");
    },
  }));

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetch({
        lookupRequest: {
          entityType: entityType,
          filter: {
            filterType: "SUBSTRING",
            values: [inputValue],
            field: "name",
          },
        },
      });
    }, 200);
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  React.useEffect(() => {
    if (data) {
      setOptions(data.lookup.response);
      if (data.lookup.response && data.lookup.response.length > 0) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
  }, [data]);

  if (error) return `Error! ${error}`;

  const handleChange = (e, v) => {
    setValue(v);
    if (props.onChange) {
      props.onChange(v);
    }
  };

  const handleInputChange = (_, v) => {
    setInputValue(v);
  };

  return (
    <Autocomplete
      value={value}
      inputValue={inputValue}
      onChange={handleChange}
      disableCloseOnSelect
      multiple
      options={options}
      getOptionLabel={(option) => option.name}
      onInputChange={handleInputChange}
      popupIcon={<SearchIcon />}
      loading={loading}
      sx={{
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: "none",
        },
        width: "100%",
        ...props.sx,
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          style={{ width: "100%" }}
          placeholder="Search"
        />
      )}
    />
  );
});

export default EntityTag;
