import { gql } from "@apollo/client";

export const BOOKMARK = gql`
  mutation bookmark($bookmark: Boolean!, $facultyId: String) {
    bookmark(bookmark: $bookmark, facultyId: $facultyId) {
      facultyIds
    }
  }
`;

export const AUTH = gql`
  mutation generateToken($googleAuthToken: String) {
    generateToken(googleAuthToken: $googleAuthToken)
  }
`;
