import {
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import * as React from "react";
import Logout from "@mui/icons-material/Logout";
import { googleLogout } from "@react-oauth/google";
import { AUTH } from "../../graphql/Mutation";
import { useMutation } from "@apollo/client";
import { Box } from "@mui/system";

export default function Login() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [credential, setCredential] = React.useState(null);

  const open = Boolean(anchorEl);
  const [authenticate] = useMutation(AUTH, {
    onCompleted: (data) => {
      dispatch({
        type: "SET_USER",
        payload: {
          user: jwtDecode(credential),
          credential: credential,
          token: data.generateToken,
        },
      });
    },
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    googleLogout();
    dispatch({ type: "SET_USER", payload: null });
  };

  const handleAuth = (cred) => {
    setCredential(cred.credential);
    authenticate({ variables: { googleAuthToken: cred.credential } });
  };

  if (user) {
    return (
      <>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{ padding: 0 }}
          >
            <Avatar
              src={user.picture}
              imgProps={{ referrerPolicy: "no-referrer" }}
            />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </>
    );
  }
  return (
    <Box sx={{ margin: "2px 12px" }}>
      <GoogleLogin
        onSuccess={handleAuth}
        type={isSmallScreen ? "icon" : "standard"}
        onError={() => {}}
        useOneTap
        auto_select
      />
    </Box>
  );
}
