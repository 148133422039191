import * as React from "react";
import AppBar from "@mui/material/AppBar";
import { Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import Login from "../../components/login/Login";
import Badge from "@mui/material/Badge";
import { Box, Divider, useMediaQuery } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { GET_BOOKMARK } from "../../graphql/Query";
import { useQuery } from "@apollo/client";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import { SIDE_PADDING } from "../../library/StyleSheet";

export default function TopBar(props) {
  const { error, loading, data, refetch } = useQuery(GET_BOOKMARK);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.formData);

  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const onClick = () => {
    if (user) {
      return navigate(
        "/search?query=" +
          encodeURIComponent(
            JSON.stringify({
              onlyBookmarked:
                formData && formData["onlyBookmarked"]
                  ? !formData["onlyBookmarked"]
                  : true,
              page: 0,
            })
          )
      );
    } else {
      dispatch({ type: "SET_OPEN_SNACKBAR", payload: true });
    }
  };
  if (error) {
    if (error.toString().includes("Access Denied")) {
      dispatch({ type: "SET_USER" });
    }
  }

  React.useEffect(() => {
    if (data) {
      dispatch({
        type: "SET_BOOKMARKS",
        payload: data?.getBookmark?.facultyIds,
      });
    }
  }, [data]);
  const bookmarks = useSelector((state) => state.bookmarks);
  return (
    <AppBar
      position="static"
      style={{
        marginBottom: "20px",
        paddingLeft: 0,
        paddingRight: 0,
        width: "100%",
        margin: 0,
        backgroundColor: "#FDFBF8",
        boxShadow: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "75px",
          alignItems: "center",
          paddingRight: SIDE_PADDING,
        }}
      >
        <Box
          sx={{
            marginRight: "20px",
            marginLeft: SIDE_PADDING,
            height: { xs: "30px", md: "40px" },
          }}
        >
          <Link to="/">
            <img
              src={isSmallScreen ? "/logo-mini.svg" : "/logo.svg"}
              height="40px"
            />
          </Link>
        </Box>
        {props.children}
        <div style={{ flexGrow: 1 }}></div>
        <IconButton
          color="primary"
          onClick={onClick}
          sx={{ marginRight: { md: "30px" } }}
        >
          <Badge badgeContent={bookmarks?.length} color="warning">
            {formData && formData["onlyBookmarked"] ? (
              <BookmarksIcon />
            ) : (
              <BookmarksOutlinedIcon />
            )}
          </Badge>
        </IconButton>
        <Login />
      </Box>
      <Divider />
    </AppBar>
  );
}
