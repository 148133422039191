import { Container, Grid, Typography } from "@mui/material";
import { SIDE_PADDING } from "../../library/StyleSheet";
import FAQ from "../FAQ";
export default function FaqSection(props) {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        margin: "0 !important",
        paddingLeft: 0,
        ...props.sx,
      }}
      spacing={10}
    >
      <Grid
        item
        xl={4}
        md={5}
        xs={0}
        sx={{
          display: { xs: "none", md: "block" },
          paddingLeft: "0px !important",
        }}
      >
        <img src="footer.svg" width="100%" />
      </Grid>
      <Grid
        item
        xl={8}
        md={7}
        xs={12}
        sx={{
          paddingLeft: {
            md: "80px !important",
            sm: "50px !important",
            xs: "20px !important",
          },
          paddingRight: SIDE_PADDING,
        }}
      >
        <Typography variant="h3" className="head" sx={{ fontWeight: "bold" }}>
          FAQ
        </Typography>
        <Container
          sx={{
            padding: "0 !important",
            margin: 0,
            marginTop: { xs: "20px", md: "50px" },
            marginBottom: "50px",
          }}
        >
          <FAQ
            title="What are h-index and i10-index?"
            body="The h-index and i10-index are measures of an academic researcher's productivity and impact. The h-index reflects the number of publications an author has produced and the number of times those publications have been cited by others, while the i10-index is the number of publications an author has written with at least ten citations each. Both indices are used to quantify an author's research impact."
          />
          <FAQ
            title="Why rate my professor's rating relevant for selecting an advisor?"
            body="Rate My Professor's ratings can be relevant for selecting an advisor because they provide insights into a potential advisor's teaching style, communication skills, and overall effectiveness. A high rating could indicate that the advisor is knowledgeable, supportive, and responsive to student's needs, while a low rating could suggest the opposite. However, it is essential to note that Rate My Professor's ratings are based on subjective opinions and may not reflect the advisor's research skills or professional expertise. Therefore, it should be considered one of many factors when selecting an advisor."
          />
          <FAQ
            title="How many professors should I select for applying to grad schools?"
            body="There is no set number of professors you should select for applying to grad schools, as it depends on each program's specific requirements and preferences. However, as a general guideline, it is recommended to have at least three to five potential advisors per program in mind when applying. This allows for a diverse range of options and increases the likelihood of finding a good match in terms of research interests and compatibility with the advisor's working style."
          />
        </Container>
      </Grid>
    </Grid>
  );
}
