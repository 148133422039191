import * as React from "react";
import "./HomePage.css";
import TopBar from "../../components/topBar/Index";
import SearchSection from "../../components/homePageSections/SearchSection";
import UtilitySection from "../../components/homePageSections/UtilitySection";
import FaqSection from "../../components/homePageSections/FaqSection";
import Footer from "../../components/footer/Footer";

export default function NewHomePage() {
  return (
    <>
      <TopBar />
      <SearchSection />
      <UtilitySection sx={{ marginTop: "50px" }} />
      <FaqSection sx={{ marginTop: "50px" }} />
    </>
  );
}
